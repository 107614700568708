/* src/components/Loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    position: fixed; /* Fixed position to cover the entire screen */
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8); /* Light background with opacity */
    z-index: 1000; /* Ensure loader is on top of other content */
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light gray background */
    border-top: 8px solid #3498db; /* Blue spinner */
    border-radius: 50%;
    width: 60px; /* Size of the loader */
    height: 60px; /* Size of the loader */
    animation: spin 1s linear infinite; /* Animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }