/* Footer.css */
.footer {
    background-color: #f1f1f1;
    padding: 10px;
    text-align: center;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #ddd;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
  }