body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.logo  a{
  align-items: center;
    display: flex;
    gap: 5px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.deals-container {
  display: flex; 
 justify-content: space-between;
  gap: 20px;
}

.deals-section {
  flex: 1;
}
.deals-list {
  column-count: 5;
  border-width: 1px solid red;
  column-gap: 20px;
}

.deal-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
  break-inside: avoid; /* Prevents cards from being split across columns */
  align-self: flex-start;
}

.deal-card:hover {
  transform: translateY(-5px);
}

.image-deal-card img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.deal-content {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  overflow: hidden;
  text-wrap: wrap;
}

.deal-content p {
  margin: 0 0 10px;
}

.deal-content a {
  color: #1a73e8;
  text-decoration: none;
}

.deal-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .deals-list {
    column-count: 1;
  }
}
small {
  display: block;
  margin-top: 15px;
  font-size: 12px;
  color: #50C878;
  text-align: right;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  margin-bottom: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
  background-color: #0056b3;
}

.pagination span {
  font-size: 16px;
  font-weight: bold;
}
