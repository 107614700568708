/* General Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    position: relative;
    z-index: 1000;
  }
  
  .logo a {
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
  
  .nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav ul li {
    margin-left: 20px;
  }
  
  .nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .nav ul li a:hover {
    color: #1a73e8;
  }
  
  /* Menu Toggle (Hamburger) */
  .menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    cursor: pointer;
  }
  
  .hamburger,
  .hamburger::before,
  .hamburger::after {
    content: '';
    display: block;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    transition: transform 0.3s ease;
  }
  
  .hamburger::before,
  .hamburger::after {
    width: 100%;
  }
  
  .hamburger::before {
    transform: translateY(-8px);
  }
  
  .hamburger::after {
    transform: translateY(8px);
  }
  
  .hamburger.open {
    transform: rotate(45deg);
  }
  
  .hamburger.open::before {
    transform: rotate(90deg) translateX(-8px);
  }
  
  .hamburger.open::after {
    transform: rotate(90deg) translateX(-8px);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .nav ul {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 24px;
      background-color: #000;
      /* width: 200px; */
      padding: 7px 15px;
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    .nav.open ul {
      display: flex;
    }
  
    .nav ul li {
      margin-left: 0;
      margin-bottom: 10px;
      text-align: center;
      padding: 10px 20px;
    }
  
    .menu-toggle {
      display: flex;
    }
  }
  